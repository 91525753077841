exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-homepage-sr-js": () => import("./../../../src/pages/homepage.sr.js" /* webpackChunkName: "component---src-pages-homepage-sr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-sr-js": () => import("./../../../src/pages/index.sr.js" /* webpackChunkName: "component---src-pages-index-sr-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-sr-js": () => import("./../../../src/pages/projects.sr.js" /* webpackChunkName: "component---src-pages-projects-sr-js" */),
  "component---src-templates-inquiry-js": () => import("./../../../src/templates/inquiry.js" /* webpackChunkName: "component---src-templates-inquiry-js" */),
  "component---src-templates-inquiry-sr-js": () => import("./../../../src/templates/inquiry.sr.js" /* webpackChunkName: "component---src-templates-inquiry-sr-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-sr-js": () => import("./../../../src/templates/page.sr.js" /* webpackChunkName: "component---src-templates-page-sr-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/single-project.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-single-project-sr-js": () => import("./../../../src/templates/single-project.sr.js" /* webpackChunkName: "component---src-templates-single-project-sr-js" */)
}

