import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import AniLink from 'gatsby-plugin-transition-link/AniLink'


import MyNavMenu from './NavMenuCustomModal'
import NavMenuCustomModalSr from './NavMenuCustomModalSr'
import archOptBlack from '../img/arch_opt_black.svg'


const Navbar = ({ locale }) => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage(
          sort: { fields: id }
          limit: 5
          filter: { locale: { locale: { eq: "en_US" } } }
        ) {
          edges {
            node {
              title
              slug
              locale {
                locale
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="top-bar navigationElementHolder header">
        <div className="logo">
          <AniLink fade direction="up" bg="#fff" duration={1} to={locale === 'en_US'? "/":'/sr/'}>
            <figure className="image">
              <img src={archOptBlack} alt="Ana Nenadovic" />
            </figure>
          </AniLink>
        </div>
        <div>
          {locale === 'en_US' ? <MyNavMenu /> : <NavMenuCustomModalSr />}
        </div>
      </div>
    )}
  />
)

export default Navbar
