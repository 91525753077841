import React, { Component } from 'react'

import allSvgLink from '../img/images.svg'

const gayArrow = `<use xlink:href=${allSvgLink}#gay_arrow_right_1 />`

class CustomBackToTop extends Component {
  render() {
    return (
      <div id="back-to-top" title="Back to top">
        <svg dangerouslySetInnerHTML={{ __html: gayArrow }} />
      </div>
    )
  }
}

export default CustomBackToTop
