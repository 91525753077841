import React from 'react'
// import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import Link from 'gatsby-plugin-transition-link'
import Helmet from 'react-helmet'
import ScrollUpButton from 'react-scroll-up-button'
// import archOptWhite from '../img/arch_opt_white.svg'
// import archOptBlack from '../img/arch_opt_black.svg'
import Navbar from './Navbar'
// import NavbarSr from './NavbarSr'
import CustomBackToTop from './CustomBackToTop'
// import allSvgLink from '../img/images.svg'

import '../scss/app.scss'

// const gayArrow = `<use xlink:href=${allSvgLink}#gay_arrow_right_1 />`
// const faceSvg = `<use xlink:href=${allSvgLink}#icon_facebook_small />`
// const instagramSvg = `<use xlink:href=${allSvgLink}#icon_instagram_small />`
// const twitterSvg = `<use xlink:href=${allSvgLink}#icon_twitter_small />`

const TemplateWrapper = ({ children, props }) => {
	// console.log('children')
	// console.log(children);
	// let pageLoc = (children.props.pageContext.langKey === 'en')? "en_US" : "sr_RS";
  return (
    <div>
      {/* {console.log('children')} */}
      {/* {console.log(pageLoc)} */}
      <Helmet title="Interior Design Studio" />
      {/* {console.log(children)} */}
      <Navbar locale={(children.props.pageContext.langKey === 'en' || children.props.pageContext.locale
	  === "en_US")? "en_US" : "sr_RS"} />

      {/* <div>{children}</div> */}
      <div className="contentHolder">{children}</div>
      <ScrollUpButton
        id=""
        title="Back to top"
        EasingType="linear"
        ShowAtPosition={300}
        AnimationDuration={1000}
        ContainerClassName="AnyClassForContainer"
        TransitionClassName="AnyClassForTransition"
      >
        <CustomBackToTop />
      </ScrollUpButton>
    </div>
  )
}
export default TemplateWrapper
