import React, { Component } from 'react'
// import PropTypes from 'prop-types'
// import { Link, StaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

// import archOptBlack from '../img/arch_opt_black.svg'
import archOptWhite from '../img/arch_opt_white.svg'
import allSvgLink from '../img/images.svg'

// const faceSvg = `<use xlink:href=${allSvgLink}#icon_facebook_small />`
const instagramSvg = `<use xlink:href=${allSvgLink}#icon_instagram_small />`
// const twitterSvg = `<use xlink:href=${allSvgLink}#icon_twitter_small />`

class MyNavMenu extends Component {
  state = { showMenu: '' }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  render() {
    const menuActive = this.state.showMenu ? 'open' : ''
    const burgerActive = this.state.showMenu ? 'active' : ''
    // console.log(this.props)
    // console.log(document)
    // if (!this.state.showMenu) {
    //   document.body.classList.remove('modalOpen')
    // } else {
    //   document.body.classList.add('modalOpen')
    // }

    return (
      <div>
        <div
          id="toggle"
          className={`button_container ${burgerActive}`}
          onClick={this.toggleMenu}
          onKeyDown={this.toggleMenu}
          role="menu"
        >
          <span className="top" />
          <span className="middle" />
          <span className="bottom" />
        </div>
        <div>
          <div className={`overlay ${menuActive}`} id="overlay">
            <nav className=" is-transparent">
              <div className="navbar-brand">
                <AniLink
                  className="navbarLogo"
                  fade
                  direction="up"
                  bg="#fff"
                  duration={1}
                  to="/"
                  onClick={this.toggleMenu}
                >
                  <figure className="image">
                    <img src={archOptWhite} alt="Ana Nenadovic" />
                  </figure>
                </AniLink>
              </div>
              <div className="navbar-lang">
                <AniLink
                  className="navbarEn catButton"
                  fade
                  direction="up"
                  bg="#fff"
                  duration={1}
                  to="/"
                  onClick={this.toggleMenu}
                >
                  En
                </AniLink>
                <AniLink
                  className="navbarSr catButton"
                  fade
                  direction="up"
                  bg="#fff"
                  duration={1}
                  to="/sr/"
                  onClick={this.toggleMenu}
                >
                  Sr
                </AniLink>
              </div>
              <div className="navbar-start">
                <ul>
                  <li>
                    <AniLink
                      fade
                      direction="up"
                      bg="#fff"
                      duration={1}
                      onClick={this.toggleMenu}
                      to="/projects/"
                    >
                      Projects
                    </AniLink>
                  </li>
                  <li>
                    <AniLink
                      fade
                      direction="up"
                      bg="#fff"
                      duration={1}
                      onClick={this.toggleMenu}
                      to="/about-us/"
                    >
                      About us
                    </AniLink>
                  </li>
                  <li>
                    <AniLink
                      fade
                      direction="up"
                      bg="#fff"
                      duration={1}
                      onClick={this.toggleMenu}
                      to="/inquiry/"
                    >
                      Inquiry
                    </AniLink>
                  </li>
                </ul>
              </div>

              <div className="navbarSocial">
                {/* <a
                  href="https://google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="faceSvg"
                    dangerouslySetInnerHTML={{ __html: faceSvg }}
                  />
                </a> */}

                <a
                  href="https://www.instagram.com/ana__nenadovic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="instagramSvg"
                    dangerouslySetInnerHTML={{ __html: instagramSvg }}
                  />
                </a>
                {/* <a
                  href="https://google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="twitterSvg"
                    dangerouslySetInnerHTML={{ __html: twitterSvg }}
                  />
                </a> */}
              </div>

              <div className="navbarEnd">
                Designed and Developed by{' '}
                <a
                  className="toSm catButton"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://singularminds.eu/"
                >
                  @singularminds
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}
// MyNavMenu.propTypes = {
//   menu: PropTypes.shape({
//     allWordpressWpApiMenusMenusItems: PropTypes.shape({
//       edges: PropTypes.object.isRequired,
//     }),
//   }),
// }

export default MyNavMenu

// export const query = graphql`
//   query LayoutQuery {
//     allWordpressWpApiMenusMenusItems {
//       edges {
//         node {
//           items {
//             title
//             url
//             object_id
//             object
//             object_slug
//             type
//             type_label
//           }
//         }
//       }
//     }
//   }
// `
